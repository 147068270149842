@use '../node_modules/@angular/material/index' as mat;

@import 'light-theme';
@import 'dark-theme';

@include mat.core();

@include mat.all-component-themes($light-theme);
@include mat.typography-hierarchy($app-typography);

.dark-theme {
  @include mat.all-component-colors($dark-theme);
}
